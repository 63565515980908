import { environment } from '../environments/environment';
import { getTablePaginatorIntl } from '../assets/typescript/table.customization';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Router, RouterModule } from '@angular/router';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ROUTES } from './recognition.app.routes';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, BrowserModule } from '@angular/platform-browser';
import { StatsEffects } from './account/user.stats/user.stats.effects';
import { RecentActivityEffects } from './account/notification/notification.effects';
import { NewFeedEffects } from './account/newsfeed/newsfeed.component/newsfeed.effects';
import { UserRewardsEffects } from './account/user.reward/user.rewards.effects';
import { TrendingTagsEffects } from './account/trending.tags/trending.tags.effects';
import * as Sentry from "@sentry/angular";

/*Providers */
import { CommonValidator } from './providers/Directive/common.validator.directive';
import { AuthInterceptor } from './providers/interceptor/auth.interceptor';
import { ErrorHandleService } from './providers/interceptor/handler.error.service';
import { RequestHandleService } from './providers/interceptor/handler.request.service';
import { ErrorInterceptor } from './providers/interceptor/error.interceptor';
import { ProvidersModule } from './providers/providers.module';


/*Component*/
import { RecognitionAppComponent } from './recognition.app.component';
import { ToastComponent } from './providers/toast/toast/toast.component';
import { SnackBarComponent } from './providers/snackbar/snackbar/snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { WishlistLoaderModule } from '../../../wishlist-loader/src/public-api';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';

/*Store*/
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { PopularSearchEffects } from './account/start.post/init.post/filtering/popular.search.effects';
import { TagSearchEffects } from './account/start.post/init.post/filtering/tag.search.effects';
import { UsageInsightsffects } from './account/usage.insights/usage.insights.effects';
import { ThankYouNotesEffects } from './account/thankyou.notes/thankyou.notes.effects';
import { RecognitionRenderFilterEffects } from './account/recognition.render/filter/recognition.render.filter.effects';
import { RecognitionRenderNormalEffects } from './account/recognition.render/normal/recognition.render.normal.effects';
import { CompanyValueEffects } from './common/tag.store/company.value.effects';
import { BusinessGoalEffects } from './common/tag.store/business.goal.effects';
import { RewardTagEffects } from './common/tag.store/reward.tag.effects';
import { CoworkerCacheServiceModule } from '../../../coworker-service';
import { hrSystemDisconnectConfirmationModule } from './common/hrSystem.disconnect.confirm.dialog';
import { RsaEncryptionModule } from 'projects/rsa-encryption';
import { StoreService } from './store/store.service';
import { RecognitionPreloadStrategyService } from './recognition.preload.service';

@NgModule({
    declarations: [
        RecognitionAppComponent,
        ToastComponent,
        SnackBarComponent,
        CommonValidator
    ],
    imports: [
        CommonModule,
        BrowserModule,
        MatIconModule,
        BrowserAnimationsModule,
        WishlistLoaderModule,
        MatSnackBarModule,
        HttpClientModule,
        hrSystemDisconnectConfirmationModule,
        RsaEncryptionModule.forRoot(environment.rsaEncryptKey),
        RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload', preloadingStrategy: RecognitionPreloadStrategyService }),
        CoworkerCacheServiceModule.forRoot(),
        ProvidersModule.forRoot(),
        StoreModule.forRoot(reducers, { metaReducers, runtimeChecks: { strictStateImmutability: false, strictActionImmutability: false, strictActionSerializability: false, strictStateSerializability: false, } }),
        !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
        EffectsModule.forRoot([StatsEffects, RecentActivityEffects, NewFeedEffects,
            UserRewardsEffects, ThankYouNotesEffects, StatsEffects,
            CompanyValueEffects, BusinessGoalEffects, RewardTagEffects, UsageInsightsffects,
            TrendingTagsEffects, PopularSearchEffects, TagSearchEffects, RecognitionRenderNormalEffects, RecognitionRenderFilterEffects])
    ],
    providers: [
        StoreService,
        RequestHandleService,
        ErrorHandleService,
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
        { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: MatPaginatorIntl, useValue: getTablePaginatorIntl() },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [RecognitionAppComponent]
})
export class RecognitionAppModule { }
