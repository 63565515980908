import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { environment } from "../../../environments/environment";
import {
  AttachmentDownloadRequest,
  AmzOnboardSettingRequest,
  AmzStateTransitionRequest,
} from "../../admin/corp/products/amazon/amazon.component/amazon.product.model";
import { InvoiceAndLineItemDto } from "../../admin/corp/manage/admin.tools/admin-tools-component/admin-tools.model";
import { ReceiptTrackingNote } from "../../admin/corp/booking/booking.components/booking.table/booking.fulfilment/booking.fulfilment.models";
import { PagedDataRequest, ShopifyOnboardingSettingRequest, VendorDetails } from "../../admin/corp/products/shopify/shopify.models";
import { SecurityKeyRequest } from "../../admin/corp/manage/security-key/security-key.model";
import { InviteSource } from "../../modals/reward.someone";

@Injectable({ providedIn: "root" })
export class CorpApiService {
  private quartzBaseUrl: string = environment.quartzBaseUrl;
  constructor(private configService: ConfigService) { }
  getBookings(params) {
    return this.configService.get(`api/v2/admin/orders/get`, params);
  }
  getBookingDetailById(orderIdentifier) {
    return this.configService.get(
      `api/v2/admin/order/${orderIdentifier}/details/get`
    );
  }
  getVCCPayments(orderIdentifier) {
    return this.configService.get(
      `api/v2/admin/order/${orderIdentifier}/vcc/data/get`
    );
  }
  updateVccAmountByIdentifier(
    orderIdentifier,
    vccIdentifier,
    currencyIdentifier,
    amount
  ) {
    return this.configService.post(
      `api/v2/admin/order/${orderIdentifier}/vcc/${vccIdentifier}/update?currencyIdentifier=${currencyIdentifier}&serializedParams=${amount}`,
      null
    );
  }
  cancelVCCPayment(orderIdentifier, vccIdentifier) {
    return this.configService.post(
      `api/v2/admin/order/${orderIdentifier}/vcc/${vccIdentifier}/cancel`,
      null
    );
  }
  getEmailTemplate(data) {
    return this.configService.post(
      `api/v2/admin/booking/email/templates`,
      data
    );
  }
  sendEmails(data) {
    return this.configService.post(`api/v2/admin/booking/email/send`, data);
  }
  enableSendEmail(orderIdentifier, emailType) {
    return this.configService.post(
      `api/v2/admin/booking/${orderIdentifier}/email/resend/enable?emailType=${emailType}`,
      null
    );
  }
  confirmBooking(orderIdentifier, params) {
    return this.configService.post(
      `api/v2/admin/order/${orderIdentifier}/confirm`,
      params
    );
  }
  uploadBookingAttachmentFile(
    orderIdentifier,
    formData,
    bookingAttachmentType
  ) {
    return this.configService.post(
      `api/v2/admin/order/${orderIdentifier}/file/attachment/upload?serializedParams=${bookingAttachmentType}`,
      formData,
      {}
    );
  }
  getBookingAttachmentFiles(orderIdentifier) {
    return this.configService.get(
      `api/v2/admin/order/${orderIdentifier}/file/get`
    );
  }
  deleteBookingAttachmentFile(orderIdentifier, bookingAttachmentId) {
    return this.configService.delete(
      `api/v2/admin/order/${orderIdentifier}/file/delete?serializedParams=${bookingAttachmentId}`,
      null
    );
  }
  resetCurrentBooking(orderIdentifier) {
    return this.configService.post(
      `api/v2/admin/order/${orderIdentifier}/reset`,
      null
    );
  }
  deleteUserPayment(id) {
    return this.configService.post(
      `api/v2/admin/booking/user-payment/delete`,
      id
    );
  }
  updateActivityPaybackAmount(id, amount) {
    return this.configService.post(
      `api/v2/admin/booking/${id}/update/activitypayamount?amount=${amount}`,
      {}
    );
  }
  refundUserPayments(identifierList) {
    return this.configService.post(
      "api/v2/admin/user/payment/refund",
      identifierList
    );
  }
  deleteUserPayments(identifierList) {
    return this.configService.post(
      `api/v2/admin/booking/user-payment/delete`,
      identifierList
    );
  }
  refundOpenBookingPayments(params) {
    return this.configService.post(
      `api/v2/admin/open/booking/payment/discard`,
      params
    );
  }
  getUserPayments(bookingIdentifier, params) {
    return this.configService.get(
      `api/v2/admin/booking/${bookingIdentifier}/user-payments/all`,
      params
    );
  }
  getGiftCardPayments(orderIdentifier, params) {
    return this.configService.get(
      `api/v2/admin/order/${orderIdentifier}/giftcards/all`,
      params
    );
  }
  getOpenBookingPayments(orderIdentifier, params) {
    return this.configService.get(
      `api/v2/admin/order/${orderIdentifier}/open/booking/payment/history/get`,
      params
    );
  }
  makeUserPaymentRequest(params) {
    return this.configService.post(
      `api/v2/admin/booking/user-payment/request`,
      params
    );
  }
  makeOpenBookingPaymentRequest(params) {
    return this.configService.post(
      `api/v2/admin/order/${params.orderIdentifier}/open/booking/payment/${params.amount}/raise?notes=${params.notes}`,
      null
    );
  }
  makeVCCPaymentRequest(
    orderIdentifier,
    currencyIdentifier,
    amount,
    vendorNotes
  ) {
    return this.configService.post(
      `api/v2/admin/order/${orderIdentifier}/raise/vcc/payment?currencyIdentifier=${currencyIdentifier}&paymentAmount=${amount}&vendorNotes=${vendorNotes}`,
      null
    );
  }
  getDirectOrderGiftCardExternals(params) {
    return this.configService.get(
      "api/v2/admin/direct/giftcardexternals/order/get",
      params
    );
  }
  getVccLogsDetails(vccIdentifier) {
    return this.configService.get(
      `api/v2/admin/booking/vcc/${vccIdentifier}/details`
    );
  }
  resendGiftCardExternalEmail(orderIdentifier) {
    return this.configService.post(
      "api/v2/admin/giftcardexternals/{orderIdentifier}/resend".replace(
        "{orderIdentifier}",
        orderIdentifier
      ),
      null
    );
  }
  getGiftCardExternalProducts(params) {
    return this.configService.get(
      "api/v2/admin/products/giftcardexternal/all",
      params
    );
  }

  getRewardSiteDefaultMessage(params: { siteId: number, email: string }) {
    return this.configService.get(`api/v1/account/site/${params.siteId}/defaultmessage`, params.email);
  }
  getCurrencies() {
    return this.configService.get(`api/v2/admin/currency/list/get`);
  }
  getMarkets(params) {
    return this.configService.get("api/v2/admin/markets/get", params);
  }
  updateMarket(id, publish) {
    return this.configService.put(
      `api/v2/admin/market/${id}/${publish}/update`,
      null
    );
  }
  addUpdateMarket(payload) {
    return this.configService.post("api/v2/admin/market/addupdate", payload);
  }
  getLocations(params) {
    return this.configService.get("api/v2/admin/location/get", params);
  }
  updateLocation(id, publish) {
    return this.configService.put(
      `api/v2/admin/location/${id}/${publish}/update`,
      null
    );
  }
  createLocation(payload) {
    return this.configService.post("api/v2/admin/location/create", payload);
  }
  getTagList(params) {
    return this.configService.get("api/v2/admin/tags", params);
  }
  addTag(params) {
    return this.configService.post("api/v2/admin/tag", params);
  }
  updateTags(params) {
    return this.configService.put(`api/v2/admin/tags`, params);
  }
  deleteProductTagList(params) {
    return this.configService.delete(`api/v2/admin/market`, params);
  }
  getTagProductList(params) {
    return this.configService.get(
      `api/v2/admin/tag/${params.tagIdentifier}/products`,
      params
    );
  }
  removeProductFromTag(params) {
    return this.configService.delete(
      `api/v2/admin/tag/${params.tagIdentifier}/product/${params.productIdentifier}`
    );
  }
  getProductListByKeyword(params) {
    return this.configService.get(`api/v2/admin/products`, params);
  }
  isOrderCancellable(identifier: string) {
    return this.configService.get(
      `api/v2/admin/cancel/order/${identifier}/check`
    );
  }
  cancelOrderedRewardBooking(identifier, reasonCode) {
    return this.configService.post(
      `api/v2/admin/cancel/order/${identifier}?bookingCancellationData=${reasonCode}`,
      null
    );
  }
  getAmazonAuthCredential() {
    return this.configService.get(`api/v2/admin/amazon/oAuth/credentials`);
  }
  syncAmazonAuthCredentials(params: object) {
    return this.configService.post(
      `api/v2/admin/amazon/credentials/sync`,
      params
    );
  }
  getAmazonOnboardedProduct(params: object) {
    return this.configService.get(
      `api/v2/admin/amazon/onboarded/products`,
      params
    );
  }
  getAmazonOnboardedProductLogHistory(params: object) {
    return this.configService.get(
      "api/v2/admin/amazon/onboard/setting",
      params
    );
  }
  getAmazonSettingProducts(params: {
    identifier: string;
    pageSize: number;
    pageIndex: number;
  }) {
    return this.configService.get(
      "api/v2/admin/amazon/setting/products",
      params
    );
  }
  getAmazonCategoriesBySearch(params: object) {
    return this.configService.get(`api/v2/admin/amazon/categories`, params);
  }
  getAllAmazonCategories() {
    return this.configService.get(
      `api/v2/admin/amazon/categories/subcategories/all`,
      null
    );
  }
  getAmazonSubCategoriesByKeyword(categoryId: string, params: object) {
    return this.configService.get(
      `api/v2/admin/amazon/category/${categoryId}/subcategories`,
      params
    );
  }
  getSearchAmazonProductGrid(
    params: object,
    pageIndex: number,
    pageSize: number
  ) {
    return this.configService.post(
      `api/v2/admin/amazon/products?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      params
    );
  }
  createAmazonOnboardSetting(payload: AmzOnboardSettingRequest) {
    return this.configService.post(
      `api/v2/admin/amazon/onboard/setting`,
      payload
    );
  }
  updateAmazonSettingLogStatus(
    identifier: string,
    payload: AmzStateTransitionRequest
  ) {
    return this.configService.post(
      `api/v2/admin/amazon/setting/${identifier}/update/state`,
      payload
    );
  }
  downloadSearchAmazonProductGrid(params: object) {
    return this.configService.post(
      `api/v2/admin/amazon/products/download`,
      params
    );
  }
  downloadOnboardedAmazonProductGrid(params: object) {
    return this.configService.post(
      `api/v2/admin/amazon/onboarded/products/download`,
      params
    );
  }
  downloadAmazonBulkUploadTemplate() {
    return this.configService.get(`api/v2/admin/amazon/bulk/upload/template`);
  }
  uploadAmazonBulkUploadTemplate(
    file: File,
    pageIndex: number = 0,
    pageSize: number = 10
  ) {
    let formData = new FormData();
    formData.append("file", file, file.name);
    return this.configService.post(
      `api/v2/admin/amazon/products/asins?isBulkUploadFlow=true&pageIndex=${pageIndex}&pageSize=${pageSize}`,
      formData,
      formData
    );
  }
  downloadAmazonApiResponseForProduct(asin: string) {
    return this.configService.post(
      `api/v2/admin/amazon/product/asin/${asin}/response/download`,
      {}
    );
  }
  getAmazonProductFromSku(asin: string) {
    return this.configService.post(
      `api/v2/admin/amazon/products/asin/${asin}`,
      null
    );
  }
  getAmazonProductsFromSkuList(
    params: string,
    pageIndex: number,
    pageSize: number
  ) {
    return this.configService.post(
      `api/v2/admin/amazon/products/asins?serializedAsins=${params}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
      {}
    );
  }
  addUpdateAmazonProduct(params: Array<object>) {
    let formData = new FormData();
    formData.append("request", JSON.stringify(params));
    return this.configService.post(
      "api/v2/admin/amazon/product/bulk/sync",
      formData,
      {}
    );
  }
  productPublishStatusUpdate(params) {
    return this.configService.put(
      "api/v2/admin/product/publish/status/update",
      params
    );
  }
  getEmailHistory(params) {
    return this.configService.get(`api/v2/admin/emails`, params);
  }
  getEmailHistoryById(id: number) {
    return this.configService.get(`api/v2/admin/queued/email/get/${id}`);
  }

  getEmailDetails(id) {
    return this.configService.get(`api/v2/admin/email/${id}`);
  }
  resendEmail(params) {
    return this.configService.post(`api/v2/admin/resend/email`, params);
  }
  getRewardInvites(params) {
    return this.configService.get(`api/v1/account/invites`, params);
  }
  deactivateInviteReminder(params) {
    return this.configService.post(
      `api/admin/invite/reminder/deactivate?inviteIds=${params.id}&deactivateReminders=${params.deactivateReminder}`,
      {}
    );
  }
  addUpdateAdminNote(request) {
    return this.configService.post(
      `api/admin/invite/admin/note/update`,
      request
    );
  }

  getClosedAccounts() {
    return this.configService.get("api/admin/Accounts/closed");
  }
  getActiveAccounts() {
    return this.configService.get("api/admin/accounts");
  }
  getAccountDetails(accountId: number) {
    return this.configService.get(`api/admin/account?accountId=${accountId}`);
  }
  getAccountTransactionsDetails(accountId: number) {
    return this.configService.get(
      `api/admin/transactions/get?accountId=${accountId}`
    );
  }
  addNewTransaction(params) {
    return this.configService.post(`api/admin/transactions`, params);
  }
  getInvoicesDetails(params) {
    return this.configService.get(`api/admin/account/invoices`, params);
  }
  downloadAccountTransaction(params) {
    return this.configService.get(
      `api/admin/transactions/download?accountId=${params.accountId}`
    );
  }
  resendArPayment(params) {
    return this.configService.post(
      `api/admin/account/invoice/record/payment`,
      params
    );
  }
  saveAccountInvoicePaymentTransaction(params) {
    return this.configService.post(
      `api/admin/account/invoice/record/transaction`,
      params
    );
  }
  resendInvoice(params) {
    return this.configService.post(`api/admin/account/invoice/resend`, params);
  }

  deleteInvoice(externalId: number) {
    return this.configService.post(
      `api/admin/account/invoice/delete?externalId=${externalId}`,
      ""
    );
  }
  createNewInvoice(params) {
    return this.configService.post(
      `api/admin/account/invite/request/invoice`,
      params
    );
  }
  getAllAuditReports() {
    return this.configService.get("api/admin/auditreports");
  }
  getAuditReportById(id: number, timeZoneOffset: string, download: boolean, fromAdminTool = false) {
    return this.configService.get(
      `api/admin/audit/reports?id=${id}&timeZoneOffset=${timeZoneOffset}&download=${download}&fromAdminTool=${fromAdminTool}`
    );
  }
  getGceBalance() {
    return this.configService.get(`api/admin/giftcards/balances`);
  }
  requeueGce() {
    return this.configService.post(`api/admin/giftcards/requeue`, {});
  }
  updateGcDenominations() {
    return this.configService.post(
      `api/v1/quartz/update/giftcard/denomination`,
      {},
      null,
      null,
      null,
      null,
      this.quartzBaseUrl
    );
  }
  requeueAutocode() {
    return this.configService.post(
      `api/admin/requeue/failed/autocode/bookings`,
      {}
    );
  }
  generateMonthlyReports() {
    return this.configService.post(`api/admin/generate/monthly/reports`, {});
  }
  getAccountInvoice() {
    return this.configService.get(`api/admin/account/invoices/review`);
  }
  getAccountMonthlyReport() {
    return this.configService.get(`api/admin/report/notpublished/all`);
  }
  manuallyTriggerTasks(params) {
    return this.configService.get(`api/v1/quartz/task/trigger/manually`, params, null, null, null, null, this.quartzBaseUrl)
  }
  flushCache(entityName) {
    return this.configService.post(
      `api/Admin/flushcache?entityName=${entityName}`,
      {}
    );
  }
  downloadReportAsAttachment(attachmentDownloadRequest: AttachmentDownloadRequest) {
    return this.configService.post(
      `api/v2/admin/download/report/attachment`,
      attachmentDownloadRequest
    );
  }

  postNotificationForBar(payload) {
    return this.configService.post(
      `api/v2/admin/notificationbar/create`,
      payload
    );
  }

  getNotificationForBar() {
    return this.configService.get(`api/v2/admin/notificationbar/get`);
  }
  getAllVisibilityTags() {
    return this.configService.get(
      "api/admin/account/product/mapping/visibility/tags/get"
    );
  }
  getProductCategories() {
    return this.configService.get("api/v2/admin/category");
  }
  getAllCountries() {
    return this.configService.get("api/admin/all/country");
  }
  getAllTags() {
    return this.configService.get("api/admin/tags/all");
  }
  getAllSelectedTags(id: number) {
    return this.configService.get(`api/admin/account/${id}/tags`);
  }
  getMonthlyReportOptionalFields() {
    return this.configService.get(
      "api/admin/account/monthlyreportoptionalfields"
    );
  }

  getAccountsData(params) {
    return this.configService.get("api/v2/admin/account/data", params);
  }
  getAllBoosts() {
    return this.configService.get("api/admin/account/boost/all/get");
  }
  getAllProducts() {
    return this.configService.get("api/v2/admin/products/active/list");
  }
  getSystemUserRoles() {
    return this.configService.get("api/admin/get/systemUserRole");
  }
  getTimePeriods() {
    return this.configService.get("api/admin/timeperiods");
  }
  getCountryProductMappings(identifier: string) {
    return this.configService.get(
      `api/admin/account/${identifier}/products/Availability/mapping/list/get`
    );
  }
  getAccountProductMapping(identifier: string) {
    return this.configService.get(
      `api/admin/account/${identifier}/products/mapping/list/get`
    );
  }
  createUpdateAccount(params, isAccountUpdate) {
    if (isAccountUpdate)
      return this.configService.post(`api/v2/admin/update/account`, params);
    else
      return this.configService.post(`api/v2/admin/create/account`, params);
  }
  uploadLogo(params: FormData) {
    return this.configService.post(`api/admin/sitelogoupload`, params, {});
  }
  getAccountEnabledSettings(id: number) {
    return this.configService.get(
      `api/v2/admin/get/account/enabled/settings?accountId=${id}`
    );
  }
  getAllAccounts(includeClosed) {
    return this.configService.get(
      `api/admin/accounts/names/list/get?includeClosed=${includeClosed}`
    );
  }
  getSiteDataAgainstCurrency(params) {
    return this.configService.get(
      `api/admin/account/${params.accountIdentifier}/currency/${params.currencyIdentifier}/data/get`
    );
  }
  getSiteAvailability(identifier: string) {
    return this.configService.get(
      `api/admin/site/${identifier}/availability/details/get`
    );
  }
  getUsersForSite(params) {
    return this.configService.get(
      `api/admin/account/${params.accountIdentifier}/currency/${params.currencyIdentifier}/users/get?searchKeyword=${params.searchKeyword}&countryIdentifiers=${params.countryIdentifiers}&groupIdentifiers=${params.groupIdentifiers}`
    );
  }
  getSiteProductMappings(id: number) {
    return this.configService.get(
      `api/admin/site/${id}/products/mapping/list/get`
    );
  }
  getSiteCategoryMappings(id: number) {
    return this.configService.get(
      `api/admin/site/${id}/product/category/mapping/data/get`
    );
  }
  createUpdateSite(params, isLegacyFlow: boolean = false) {
    return this.configService.post(
      `api/admin/createupdatesite?isLegacyFlow=${isLegacyFlow}`,
      params
    );
  }
  getSiteTemplate(params: { searchKey: string, pageIndex: number, pageSize: number }) {
    return this.configService.get(`api/v2/admin/site/templates`, params)
  }
  downloadSiteDetails(params) {
    return this.configService.get(`api/admin/site/details/download`, params);
  }
  getAccountMonthlyReportPublishedDate() {
    return this.configService.get(`api/admin/report/published/date`);
  }
  getTransactionTypes() {
    return this.configService.get(`api/admin/account/invoice/lineItem/transactionType`);
  }
  getInvoiceLineItems(invoiceId: number) {
    return this.configService.get(`api/admin/account/invoice/lineItems/${invoiceId}`);
  }
  saveInvoiceLineItems(params: InvoiceAndLineItemDto) {
    return this.configService.post(`api/admin/save/account/invoice/lineItems`, params);
  }

  downloadAccountInvoice(id: number) {
    return this.configService.get(`api/admin/account/invoice/download?invoiceId=${id}`);
  }
  getGceProviders() {
    return this.configService.get(`api/v2/admin/get/giftcard/providers`);
  }
  getStuckGiftCards(params: { gceProviderId: number, pageIndex: number, pageSize: number }) {
    return this.configService.get(`api/v2/admin/get/stuck/giftcard`, params);
  }
  downloadStuckGiftCards(params: { gceProviderId: number }) {
    return this.configService.post(`api/v2/admin/download/stuck/giftcard?gceProviderId=${params.gceProviderId}`, {});
  }
  disabledNotificationBar(payload, identifier) {
    return this.configService.put(`api/v2/admin/notificationbar/update?identifier=${identifier}`, payload);
  }
  getTravelPackageBookingDetails(params: { identifier: string, challenge: string }) {
    return this.configService.get(`api/catalog/travelpackages/booking/details`, params);
  }
  getTravelerDetails(params: { identifier: string, challenge: string, wishlistExternalId: number }) {
    return this.configService.get(`api/catalog/travelpackage/traveler/info/${params.identifier}/${params.challenge}/${params.wishlistExternalId}`);
  }
  getTravelerDocuments(params: { identifier: string, challenge: string, wishlistExternalId: number }) {
    return this.configService.get(`api/catalog/travelpackage/booking/travelerdocument/getdocument`, params);
  }
  getTravelReceiptTrackings(params: { identifier: string, challenge: string, orderId: number }) {
    return this.configService.get(`api/catalog/travelpackages/booking/receipttracking/notes/get`, params);
  }
  updateTravelReceiptTrackings(params: { identifier: string, challenge: string, requestData: ReceiptTrackingNote }) {
    return this.configService.post(`api/catalog/travelpackages/booking/receipttracking/notes/update/${params.identifier}/${params.challenge}`, params.requestData);
  }
  getTravelPayments(params: { orderId: number }) {
    return this.configService.get(`api/b2b/travelpackage/payment/get`, params);
  }
  updateTravelPayments(params) {
    return this.configService.post(`api/b2b/booking/payment/request`, params);
  }
  updateTravelerDocuments(params, travelerDocumentDetailsRequest) {
    return this.configService.post(`api/catalog/travelpackage/booking/travelerdocument/upload/${params.identifier}/${params.challenge}`, travelerDocumentDetailsRequest);
  }
  completeV2TravelBooking(params: [{ notes: string, wishlistExternalId: number }]) {
    return this.configService.post(`api/admin/travel/booking/status/delivered`, params);
  }
  uploadTravelBookingAttachmentFile(
    params,
    formData,
  ) {
    return this.configService.post(
      `api/b2b/travelpackage/booking/traveler/document/upload?identifier=${params.identifier}&challenge=${params.challenge}`,
      formData,
      {}
    );
  }
  deleteTravelBookingDocument(params) {
    return this.configService.get(`api/b2b/travelpackage/booking/traveler/document/delete`, params);
  }
  postEmailTemplate(payload) {
    return this.configService.post(`api/v2/admin/custom/template/create`, payload);
  }
  getAudience() {
    return this.configService.get(`api/v2/admin/audience/get`);
  }
  searchCoworker(params) {
    return this.configService.get(`api/v2/admin/employees`, params);
  }
  getEmailTemplates(params) {
    return this.configService.get(`api/v2/admin/custom/template/get`, params);
  }
  updateEmailTemplateStatus(payload, params) {
    return this.configService.patch(`api/v2/admin/custom/template/status-update`, payload, params);
  }
  updateEmailTemplate(payload, identifier) {
    return this.configService.put(`api/v2/admin/custom/template/update?identifier=${identifier}`, payload);
  }
  getAmazonProductVariants = (identifier: string, index: number) => this.configService.get(`api/v2/admin/amazon/product/${identifier}/variants`, { pageIndex: index });
  updateAmazonProductVariants = (identifier: string, params) => this.configService.put(`api/v2/admin/product/${identifier}/manage/variants`, params);


  // product collection apis 
  productSearch(params) {
    return this.configService.get(`api/v2/admin/collection/product/search`, params);
  }

  collectionFilterEntity(payload) {
    return this.configService.post(`api/v2/admin/collection/filter/entity/search`, payload);
  }

  createCollection(payload) {
    return this.configService.post(`api/v2/admin/collection`, payload);
  }

  updateCollection(payload) {
    return this.configService.put(`api/v2/admin/collection`, payload);
  }

  getAutomatedCollectionProduct(params) {
    return this.configService.get(`api/v2/admin/automated/collection/products`, params);
  }

  getCollections(params) {
    return this.configService.get(`api/v2/admin/collections`, params);
  }

  updateCollectionStatus(payload) {
    return this.configService.put(`api/v2/admin/collection/status/bulk`, payload);
  }

  getCollectionData(collectionId) {
    return this.configService.get(`api/v2/admin/collection/${collectionId}`);
  }

  // display & banners
  getPages = () => this.configService.get(`api/v2/admin/pages`);
  postBannerBulk = (payload) => this.configService.post(`api/v2/admin/banner/bulk/upsert`, payload);
  getBannerDetails = (pageIdentifier, queryParams) => this.configService.get(`api/v2/admin/page/${pageIdentifier}/banners`, queryParams);
  deleteBanner = (bannerIdentifiers) => this.configService.post(`api/v2/admin/banner`, bannerIdentifiers);
  getAccountDetail = (params) => this.configService.get(`api/v2/admin/account/detail`, { identifier: params });
  getShopifyOnboardingSettings = (params: PagedDataRequest) => this.configService.get(`api/v2/admin/shopify/onboarding/settings`, params);
  deleteShopifyOnboardingSetting = (identifier: string) => this.configService.put(`api/v2/admin/shopify/onboarding/setting/delete?identifier=${identifier}`, {});
  createShopifyOnboardingSetting = (params: ShopifyOnboardingSettingRequest) => this.configService.post(`api/v2/admin/shopify/onboarding/setting/create`, params);
  toggleShopifyOnboardingSetting = (params: { identifier: string, active: boolean }) => this.configService.put(`api/v2/admin/shopify/onboarding/setting/toggle?identifier=${params.identifier}&active=${params.active}`, {});
  getPagedShopifyProduct = (params: { pageSize: number, continuationToken: string, filters: string }) => this.configService.get(`api/v2/admin/shopify/products`, params);
  getPagedShopifyVendors = (params: { pageIndex: number, pageSize: number, searchKey: string }) => this.configService.get(`api/v2/admin/shopify/vendors`, params);
  editVendorDetails = (params: VendorDetails) => this.configService.put(`api/v2/admin/edit/vendor/details`, params);
  getShopifyShipmentDetails = (identifier: string) => this.configService.get(`api/v2/admin/shopify/shipment/details`, { identifier });
  getUserOrderedRewardDetails = (identifier: string) => this.configService.get(`api/v2/admin/order/${identifier}`);
  getMarkedAsFeatured = (payload) => this.configService.post(`api/v2/admin/update/set/featured`, payload);
  getBrandForDropdown = (params) => this.configService.get(`api/v2/admin/brand/dropdown/get`, params);
  createBrand = (payload) => this.configService.post(`api/v2/admin/brand/insert`, payload);
  getBrands = (params) => this.configService.get(`api/v2/admin/brand/all/info/get`, params);
  getAllCategories = (params) => this.configService.get(`api/v2/admin/category/all/info/get`, params);
  getAllSubCategories = (params) => this.configService.get(`api/v2/admin/subcategory/all/info/get`, params);
  getAllVendors = (params) => this.configService.get(`api/v2/admin/vendor/all/info/get`, params);
  createSubCategory = (payload) => this.configService.post(`api/v2/admin/subcategory/insertorupdate`, payload);
  getIndividualSubCategories = (identifier) => this.configService.get(`api/v2/admin/subcategory/${identifier}/detail/get`);
  getIndividualCategory = (identifier) => this.configService.get(`api/v2/admin/category/${identifier}/detail/get`);
  updateCategory = (payload) => this.configService.post(`api/v2/admin/category/update`, payload);
  getIndividualBrand = (identifier) => this.configService.get(`api/v2/admin/brand/${identifier}/detail/get`);
  getIndividualVendorDetails = (identifier) => this.configService.get(`api/v2/admin/vendor/${identifier}/detail/get`);
  updateBrand = (payload) => this.configService.post(`api/v2/admin/brand/update`, payload);
  getHolidays = () => this.configService.get(`api/v2/admin/holidays`);
  getSecurityKey = () => this.configService.get(`api/admin/qr/code/get`);
  generateNewSecurityKey = (params: SecurityKeyRequest) => this.configService.post(`api/admin/change/security/key`, params);
  getPaymarkedCardStatus = (params) => this.configService.get(`api/catalog/giftcardexternal/paymarked/get`, params)
  postThemePattern = (payload) => this.configService.post(`api/v2/admin/themes/pattern/search`, payload);
  toggleSyncSettingApiCall = (params, identifier) => this.configService.put(`api/v2/admin/amazon/setting/${identifier}/cease/sync?ceaseSync=${params}`, null)
  getOccasion = (identifier: string = "", inviteSource: InviteSource = null) => this.configService.get(`api/v2/admin/invite/occasions?identifier=${identifier}&inviteSource=${inviteSource}`)
  cancelVcc = (identifier: string) => this.configService.post(`api/v2/admin/cancel/vcc?vccIdentifier=${identifier}`, null);
  getNotificationFeatures = () => this.configService.get('api/v2/admin/features');

  notificationUrl = 'api/v2/admin/notification/criteria';
  postNotificationData = (payload: unknown) => this.configService.post(this.notificationUrl, payload);
  updateNotificationData = (payload: unknown) => this.configService.put(this.notificationUrl, payload);
  getBellNotifications = (params: unknown) => this.configService.get('api/v2/admin/notification/criterias', params);
  deleteBellNotifications = (params: unknown) => this.configService.delete(this.notificationUrl, params);
  getParticularBellNotification = (params: unknown) => this.configService.get(this.notificationUrl, params);
}
