import { NgModule } from "@angular/core";
import { ModuleWithProviders } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { WindowResizeService } from "./window.resize/window.resize.service";
import { LocalStorageService } from "./localstorage/localStorage.service";
import { AuthService } from "./authentication/auth.service";
import {
  AuthenticationGuardService,
  AccountRouteGuardService,
  AdminRouteGuardService,
  AdministratorsRouteGuardService,
  NonAdministratorsRouteGuardService,
  ChannelPartnerRouteGuardService,
  AccountWithProgramAdminRouteGuardService,
  RouteGuardLogoutService,
  SaveChangesGuardService
} from "./authentication/route.guard.service";
import { UserGuardService } from "./authentication/user.guard.service";
import { SnackBarService } from "./snackbar/snackbar.service";
import { ToastService } from "./toast/toast.service";
import { ToastCloseService } from "./toast/toaster.close.service";
import { ConfigService } from "./api.service/config.service";
import { AccountApiService } from "./api.service/account.api.service";
import { AdminApiService } from "./api.service/admin.api.service";
import { InfoApiService } from "./api.service/info.api.service";
import { ApiService } from "./api.service/normal.api.service";
import { CommonUtilityService } from "./api.service/common.utility.service";
import { CorpApiService, } from "./api.service/corp.api.service";
import { InsightApiService } from "./api.service/insight.api.service";
import { WalletApiService } from "./api.service/wallet.api.service";
import { FeatureWalkThroughApiService } from "./api.service/walk.through.api.service";
import { FrostedService } from "./frosted/frosted.service";
import { NgxLinkifyjsService } from "ngx-linkifyjs";
import { UserTrackingService } from "./tracking/user.tracking.service"
import { TeamsAuthService } from "./authentication/teams.auth.service";
import { AuthServiceMSSSO } from "./api.service/auth.sso.service";
import { UserRoleService } from "./permission/user.role.service";
import { RecognitionService } from "./recognition/recognition.service";
import { RewardSomeoneService } from "./rewardsomeone/reward.someone.service";
import { NotificationService } from "./notification/notification.service";
import { SnackbarCloseService } from "./snackbar/snackbar.close.service";
import { UserLocationService } from "./user.location/user.location.service";
import { UtilityService } from "./utility/utility.service";
import { RecognitionHandlerService } from './recognition/recognition.handler';
import { RecognitionFilteringService } from './recognition/recognition.filtering.service';
import { KinectionService } from './recognition/kinection.service';
import { FilterDataService } from './recognition/filter.data.service';
import { SignalRService } from './signalR/signalR.service';
import { SearchCoWorkerService } from "./coworker/search.coworker";
import { CookieStorageService } from "./cookiestorage/cookieStorage.service";
import { WalletConfigService } from "./permission/wallet.config.service";
import { UrlUtilityService } from "./url/url.utility.service";
import { AccountConfigService } from "./permission/account.config.service";
import { AppPlatformService } from "./platform/app.platform.service";
import { EncodeDecodeService } from "./encode.decode/encode.decode.service";
import { ZendeskService } from "./zendesk";
import { EncryptDecryptService } from "./encryption";
import { WlWalkThroughValueService } from "./wl-walk-through";
import { LayoutDialogService } from "./layout";
import { ChecklistDatabaseManager } from "../market-place/component/filter/category-filter/checklist.datasource";
@NgModule({
  imports: [
    MatDialogModule
  ]
})

export class ProvidersModule {

  static forRoot(): ModuleWithProviders<ProvidersModule> {
    return {
      ngModule: ProvidersModule,
      providers: [
        WindowResizeService,
        LocalStorageService,
        CookieStorageService,
        SnackBarService,
        SnackbarCloseService,
        ToastService,
        ToastCloseService,
        ConfigService,
        InfoApiService,
        ApiService,
        AdminApiService,
        AccountApiService,
        InsightApiService,
        WalletApiService,
        CorpApiService,
        FeatureWalkThroughApiService,
        FrostedService,
        NgxLinkifyjsService,
        UserTrackingService,
        AuthenticationGuardService,
        AccountRouteGuardService,
        AdminRouteGuardService,
        AdministratorsRouteGuardService,
        NonAdministratorsRouteGuardService,
        ChannelPartnerRouteGuardService,
        AccountWithProgramAdminRouteGuardService,
        UserGuardService,
        RouteGuardLogoutService,
        AuthService,
        TeamsAuthService,
        AuthServiceMSSSO,
        AccountConfigService,
        UserRoleService,
        RecognitionService,
        RewardSomeoneService,
        NotificationService,
        UserLocationService,
        UtilityService,
        RecognitionHandlerService,
        RecognitionFilteringService,
        KinectionService,
        FilterDataService,
        SignalRService,
        SearchCoWorkerService,
        CommonUtilityService,
        WalletConfigService,
        UrlUtilityService,
        SaveChangesGuardService,
        AppPlatformService,
        EncodeDecodeService,
        ZendeskService,
        EncryptDecryptService,
        WlWalkThroughValueService,
        LayoutDialogService,
        ChecklistDatabaseManager,
      ]
    }
  }
}
