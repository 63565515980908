import { inject, Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { UserGuardService } from 'projects/recognition/src/app/providers/authentication/user.guard.service';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from './providers/localstorage/localStorage.service';
import { UserLocalStorageKey } from './modals/keys/local.storage';
import { EncryptDecryptService } from './providers/encryption';

@Injectable({ providedIn: 'root' })

export class RecognitionPreloadStrategyService implements PreloadingStrategy {
  userRoles: string[] = [];
  private readonly _userGuardService = inject(UserGuardService);
  private readonly _localStorageService = inject(LocalStorageService);
  private readonly _encryptDecryptService = inject(EncryptDecryptService);

  constructor() {
    const loggedInUserInfo = this._encryptDecryptService.decrypt(this._localStorageService.get(UserLocalStorageKey.User));
    try {
      this.userRoles = JSON.parse(loggedInUserInfo?.roles) ?? [];
    } catch {
      this.userRoles = [];
    }
  }

  preload(route: Route, loadModule: () => Observable<any>): Observable<any> {
    if (this._userGuardService.isLoggedIn()) {
      if (route?.data?.['preload'])
        return loadModule();
      else {
        const currentRoute = window.location.pathname;
       
        if (this.isUserRoleHasAccess(route?.data?.['allowedUserRoles']) && currentRoute?.includes(route?.data?.['parentRoute'])) {
          return loadModule();
        }
        else {
          return of(null);
        }
      }
    } else {
      return of(null);
    }
  }

  isUserRoleHasAccess = (routeRoles: string[]) => (routeRoles?.some(x => this.userRoles.includes(x)));
}