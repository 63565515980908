import { environment } from "../environments/environment";
import { HostListener, OnInit } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { LocalStorageService } from "./providers/localstorage/localStorage.service";
import { WindowResizeService } from './providers/window.resize/window.resize.service';

@Component({
  selector: "recognition-app-root",
  templateUrl: "./recognition.app.component.html",
  styleUrls: ["./recognition.app.component.scss"],
})
export class RecognitionAppComponent implements OnInit, OnDestroy {
  public production: boolean = environment.production;
  public development: boolean = environment.development;
  public iSDebug: boolean = environment.isDebug;
  public appUpdateServiceFrosted$: Subscription;
  private routeEventTracking$: Subscription;
  constructor(private title: Title, private meta: Meta, private windowResizeService: WindowResizeService, private localStorageService: LocalStorageService) {
    this.title.setTitle("Wishlist");
    this.setMetaTagForCSP();
  }

  ngOnInit() {
    this.detectWindowSize();
    this.removeExpiredToken();
  }

  ngOnDestroy(): void {
    this.routeEventTracking$?.unsubscribe();
  }

  setMetaTagForCSP() {
    let defaultSrc = "";
    let scriptSrc = "";
    let styleSrc = "";
    let fontSrc = "";
    let imageSrc = "";
    let mediaSrc = "";
    let frameSrc = "";
    if (this.production) {
      defaultSrc = "default-src  'self' data: gap: mediastream: blob: filesystem: https://*.enjoywishlist.com  http://*.enjoywishlist.com ws://*.enjoywishlist.com https://*.azurewebsites.net https://maps.googleapis.com/ https://googleapis.com http://static.enjoywishlist.com https://static.enjoywishlist.com api.amplitude.com https://graph.microsoft.com/v1.0/me https://login.microsoftonline.com  https://*.zendesk.com wss://*.zendesk.com https://*.zopim.com wss://*.zopim.com https://*.zopim.io https://*.zdassets.com https://*.clarity.ms https://www.google-analytics.com https://accounts.google.com/gsi/ https://assets.braintreegateway.com api.braintreegateway.com client-analytics.braintreegateway.com *.braintree-api.com https://*.paypal.com https://*.survicate.com https://api2.amplitude.com/2/httpapi https://uuishlistbeta.blob.core.windows.net *.sentry.io; ";
      scriptSrc = "script-src 'self' https://apis.google.com https://maps.googleapis.com https://googleapis.com http://maps.googleapis.com https://js.braintreegateway.com https://js.maxmind.com https://js.maxmind.com/js/apis/geoip2/v2.1/geoip2.js cdn.amplitude.com https://*.zdassets.com cdn.form.io https://cdn.form.io/ace/1.4.8/ace.js https://cdn.form.io/ace/1.4.10/mode-html.js https://cdn.form.io/ace/1.4.8/mode-json.js https://cdn.form.io/ace/1.4.10/ace.js https://cdn.form.io/ace/1.4.10/theme-xcode.js https://*.clarity.ms https://www.googletagmanager.com https://www.google-analytics.com/analytics.js https://accounts.google.com/gsi/client  https://www.paypalobjects.com https://*.paypal.com https://*.braintreegateway.com js.braintreegateway.com https://www.google.com/recaptcha/api.js https://www.gstatic.com https://*.survicate.com 'sha256-sk0XBG3tA5scUkaKFe+F17uQBwN0rNTGvRyarDrc0ns=';";
      styleSrc = "style-src 'self' 'unsafe-inline' fonts.googleapis.com fonts.gstatic.com data: cdn.form.io/flatpickr/flatpickr.min.css https://www.googletagmanager.com https://accounts.google.com/gsi/style https://*.survicate.com; ";
      fontSrc = "font-src 'self' http://static.enjoywishlist.com https://static.enjoywishlist.com https://*.survicate.com https://fonts.gstatic.com data:; ";
      imageSrc = "img-src * data: blob:; ";
      mediaSrc = "media-src * data:; ";
      frameSrc = "frame-src * data:; ";
    }
    else if (this.development) {
      defaultSrc = "default-src  'self' data: gap: mediastream: blob: filesystem: https://*.enjoywishlist.com  http://*.enjoywishlist.com ws://*.enjoywishlist.com https://*.azurewebsites.net https://maps.googleapis.com/ https://googleapis.com https://*.zendesk.com wss://*.zendesk.com https://*.zopim.com wss://*.zopim.com https://*.zopim.io https://*.zdassets.com https://*.clarity.ms https://www.google-analytics.com https://accounts.google.com/gsi/ https://assets.braintreegateway.com https://payments.sandbox.braintree-api.com https://api.sandbox.braintreegateway.com https://origin-analytics-sand.sandbox.braintree-api.com https://www.paypal.com client-analytics.sandbox.braintreegateway.com *.braintree-api.com https://login.microsoftonline.com https://api2.amplitude.com/2/httpapi https://uuishlistbeta.blob.core.windows.net *.sentry.io;";
      scriptSrc = "script-src 'self' https://apis.google.com https://maps.googleapis.com https://googleapis.com http://maps.googleapis.com https://origin-analytics-sand.sandbox.braintree-api.com https://js.maxmind.com https://js.maxmind.com/js/apis/geoip2/v2.1/geoip2.js cdn.amplitude.com https://*.zdassets.com cdn.form.io https://cdn.form.io/ace/1.4.8/ace.js https://cdn.form.io/ace/1.4.10/mode-html.js https://cdn.form.io/ace/1.4.8/mode-json.js https://cdn.form.io/ace/1.4.10/ace.js https://cdn.form.io/ace/1.4.10/theme-xcode.js  https://*.clarity.ms https://www.googletagmanager.com https://www.google-analytics.com/analytics.js https://accounts.google.com/gsi/client https://www.paypalobjects.com https://*.paypal.com https://*.braintreegateway.com js.braintreegateway.com https://www.google.com/recaptcha/api.js https://www.gstatic.com 'sha256-sk0XBG3tA5scUkaKFe+F17uQBwN0rNTGvRyarDrc0ns=';";
      styleSrc = "style-src 'self' 'unsafe-inline' fonts.googleapis.com fonts.gstatic.com data: cdn.form.io/flatpickr/flatpickr.min.css https://www.googletagmanager.com https://accounts.google.com/gsi/style; ";
      fontSrc = "font-src 'self' http://static.enjoywishlist.com https://static.enjoywishlist.com  https://fonts.gstatic.com data:; ";
      imageSrc = "img-src * data: blob:; ";
      mediaSrc = "media-src * data:; ";
      frameSrc = "frame-src * data:; ";
    }
    else {
      defaultSrc = "default-src  'self' data: gap: mediastream: blob: filesystem: http://localhost:46923/ ws://localhost:46923 http://localhost:5001/ ws://localhost:5001 http://localhost:7071/ https://maps.googleapis.com/ https://googleapis.com http://static.enjoywishlist.com https://static.enjoywishlist.com api.amplitude.com https://graph.microsoft.com/v1.0/me https://login.microsoftonline.com  https://*.zendesk.com wss://*.zendesk.com https://*.zopim.com wss://*.zopim.com https://*.zopim.io https://*.zdassets.com https://accounts.google.com/gsi/ https://assets.braintreegateway.com https://payments.sandbox.braintree-api.com https://api.sandbox.braintreegateway.com https://origin-analytics-sand.sandbox.braintree-api.com https://www.paypal.com client-analytics.sandbox.braintreegateway.com *.braintree-api.com https://api2.amplitude.com/2/httpapi https://uuishlistbeta.blob.core.windows.net https://*.clarity.ms;";
      scriptSrc = "script-src 'self' https://apis.google.com https://maps.googleapis.com http://maps.googleapis.com https://googleapis.com https://js.braintreegateway.com https://api.sandbox.braintreegateway.com https://origin-analytics-sand.sandbox.braintree-api.com https://js.maxmind.com https://js.maxmind.com/js/apis/geoip2/v2.1/geoip2.js cdn.amplitude.com https://*.zdassets.com cdn.form.io https://cdn.form.io/ace/1.4.8/ace.js https://cdn.form.io/ace/1.4.10/mode-html.js https://cdn.form.io/ace/1.4.8/mode-json.js https://cdn.form.io/ace/1.4.10/ace.js https://cdn.form.io/ace/1.4.10/theme-xcode.js https://accounts.google.com/gsi/client https://www.paypalobjects.com https://*.paypal.com https://*.braintreegateway.com js.braintreegateway.com https://www.google.com/recaptcha/api.js https://www.gstatic.com 'sha256-sk0XBG3tA5scUkaKFe+F17uQBwN0rNTGvRyarDrc0ns=' https://*.clarity.ms;";
      styleSrc = "style-src 'self' 'unsafe-inline' fonts.googleapis.com fonts.gstatic.com data: cdn.form.io/flatpickr/flatpickr.min.css https://accounts.google.com/gsi/style ; ";
      fontSrc = "font-src 'self' http://static.enjoywishlist.com https://static.enjoywishlist.com  https://fonts.gstatic.com data:; ";
      imageSrc = "img-src * data: blob:; ";
      mediaSrc = "media-src * data:; ";
      frameSrc = "frame-src * data:; ";
    }

    let content =
      defaultSrc +
      scriptSrc +
      styleSrc +
      fontSrc +
      imageSrc +
      mediaSrc +
      frameSrc;

    this.meta.updateTag({
      "http-equiv": "Content-Security-Policy",
      content: content,
    });
  }

  detectWindowSize() {
    let windowWidth = window.innerWidth;
    this.windowResizeService.detectWindowSize(windowWidth);
  }

  removeExpiredToken() {
    this.localStorageService.remove('token_expired');
  }
}
