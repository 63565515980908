import { Injectable } from "@angular/core";
import { occasion_last_date, RedeemTimer } from "projects/assets/constant/timer.info";
import { image_travel_pdp, login_bg, occasional_login_background } from "projects/assets/images/azure";
import { environment } from "projects/recognition/src/environments/environment";
import { CategoryBehavior, CategoryName, MarketplaceCategory } from "../../modals/category";
import { IsoDateRegex } from "../../modals/keys/regex";
import { BrowseProduct, CategoryApiData, PopularProducts, Product } from "../../modals/product";
import { TimePeriod } from "../../modals/user.budget";
import { EncryptDecryptService } from "../encryption";
import { MarketplaceFilter } from "../../market-place/model/filter";
import { Observable, retry, Subject } from "rxjs";
import { RewardOptions } from "../../modals/recognition";
import { WlSnackbarConfig, WlSnackbarInfoType, WlSnackbarService, WlSnackbarType } from "projects/wl-snackbar";
import { GCFilter, MarketPlaceFilterRequest, ProductCatalogPagination } from "../../market-place/model/request";
import { PAGE_EXPERIENCE_COLLECTION, PAGE_MP_FOOD_AND_BEV, PAGE_ONLINE_COLLECTION, PAGE_PHYSICAL_COLLECTION, PAGE_SWAP_COLLECTION } from "../../market-place/model/wallet";
import { MarketplaceRoute } from "../../common/page.route";
import { Router } from "@angular/router";
import { ChoiceSuggestion } from "../../modals/reward.someone";
import { COUNTRY_LIST } from "projects/assets/constant/country.list";

@Injectable({ providedIn: "root" })
export class CommonUtilityService {
  private resetSearchMPResearchSubject = new Subject<boolean>();
  private zipCodeHandler$ = new Subject<string>();

  constructor(readonly _encryptDecryptService: EncryptDecryptService, private _wlSnackbarService: WlSnackbarService, private _router: Router) {
  }

  getDateStringFormat(value, type: string = 'Month'): string {
    if (value) {
      let date: Date = new Date(value);
      if (this.isIsoDate(value)) {
        let utcDateAtLocal = new Date(value);
        let time = utcDateAtLocal.getTime();
        let timeOffset = utcDateAtLocal.getTimezoneOffset();
        date = new Date(time + timeOffset);
      }

      let dateString = '';

      switch (type) {
        case 'Month': dateString = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear(); break;
        case 'Year': dateString = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate(); break;
        case 'Date': dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear(); break;
      }
      return dateString;
    }
    return value;
  }

  isIsoDate(value): boolean {
    return IsoDateRegex.test(value) ? true : false;
  }

  getDateTimeString(value) {
    let date = new Date(value);
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      ", " +
      this.getDateAmPm(date)
    );
  }

  getFileDownloadDateTimeString(value) {
    let date = new Date(value);
    return (
      date.getMonth() +
      1 +
      "_" +
      date.getDate() +
      "_" +
      date.getFullYear() +
      "_" +
      date.getHours() +
      "_" +
      date.getMinutes() +
      "_" +
      date.getSeconds()
    );
  }

  getDateAmPm(value, removeZero = false) {
    let date = new Date(value);
    let isPM = date.getHours() >= 12;
    let isMidday = date.getHours() == 12;
    let hours = date.getHours() - (isPM && !isMidday ? 12 : 0);
    let time =
      [
        hours >= 10 ? hours : !removeZero ? "0" + hours : hours,
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes(),
      ].join(":") + (isPM ? " PM" : " AM");
    return time;
  }

  getDateStringFormatTo2(value) {
    let date: Date = undefined;
    if (isIsoDate(value)) {
      let utcDateAtLocal = new Date(value);
      let time = utcDateAtLocal.getTime();
      let timeOffset = utcDateAtLocal.getTimezoneOffset();
      date = new Date(time + timeOffset);
    } else {
      date = new Date(value);
    }

    let day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
    let month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    let year = date.getFullYear();
    return month + "/" + day + "/" + year;

    function isIsoDate(value): boolean {
      return IsoDateRegex.test(value) ? true : false;
    }
  }

  downloadFile(arrayBuffer, fileName) {
    if (arrayBuffer?.byteLength > 0) {
      var fileContent = arrayBufferToBase64(arrayBuffer);
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/octet-stream;base64," + fileContent
      );
      element.setAttribute("download", fileName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    function arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }
  }

  downloadFilePreview(base64String: string) {
    if (base64String?.length > 0) {
      let file = base64toBlob(base64String, "application/pdf;base64");
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank") // using directly to remove circular dependency.
    }

    function base64toBlob(base64Data, contentType) {
      contentType = contentType || "";
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    }
  }

  downloadFileFromBase64ByteString(fileData, fileName): Promise<boolean> {
    return new Promise((resolve) => {
      let file = { name: fileName, base64: fileData };
      if (file && file.name && file.base64) {
        var fileContent = file.base64;
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:application/octet-stream;base64," + fileContent
        );
        element.setAttribute("download", file.name);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        resolve(true);
      }
      else {
        resolve(false);
      }
    });
  }

  formatCurrentNumberToNormalNumber(value) {
    if (value) {
      let numericalLimit = "";
      numericalLimit = JSON.parse(JSON.stringify(value));

      numericalLimit =
        numericalLimit != null && numericalLimit != ""
          ? numericalLimit.replace(/\$|,/g, "")
          : null;
      return numericalLimit;
    } else {
      return null;
    }
  }

  formatNormalNumberToCurrencyNumber(input: KeyboardEvent, value) {
    if (!input && !value) {
      return null;
    }
    if (typeof value == "number") {
      value = value.toString();
    }
    let inputValue = "";
    if (input?.key) {
      if (input.key == "Backspace") {
        inputValue = value == null ? "" : value.substring(0, value.length - 1);
      } else {
        inputValue = value == null ? input.key : value + input.key;
      }
    } else {
      inputValue = value;
    }

    if (inputValue === "") {
      return;
    }

    if (inputValue.indexOf(".") >= 0) {
      let decimal_pos = inputValue.indexOf(".");

      let leftSide = inputValue.substring(0, decimal_pos);
      let rightSide = inputValue.substring(decimal_pos);

      leftSide = formatNumber(leftSide);
      rightSide = formatNumber(rightSide);
      rightSide = rightSide.substring(0, 2);

      inputValue = "$" + leftSide + "." + rightSide;
    } else {
      inputValue = formatNumber(inputValue);
      inputValue = "$" + inputValue;
    }

    return inputValue;

    function formatNumber(amountFormat) {
      return amountFormat
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  getGuidAtLocalForCalculation(): string {
    let s = [];
    let hexDigits = "0123456789abcdef";
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = "-";

    let uuid = s.join("");
    return uuid;
  }

  getUtcDateWithNoChangeDateTime(date: Date) {
    if (!date) { return null }
    let conversionDate = isIsoDate(date) ? new Date(date.toLocaleString()) : new Date(new Date(date).toISOString());
    let utcDate = new Date(conversionDate.getTime() - (conversionDate.getTimezoneOffset() * 60000)).toISOString();
    return utcDate;

    function isIsoDate(value): boolean {
      return IsoDateRegex.test(value) ? true : false;
    }
  }

  getLocalDateWithNoChangeDateTimeFromUTC(value: Date | string): Date {
    let modifiedValue = null
    if (value) {
      if (isIsoDate(value)) {
        let conversionDate = new Date(value.toLocaleString());
        modifiedValue = new Date(conversionDate.getTime() + conversionDate.getTimezoneOffset() * 60000);
      }
      else {
        modifiedValue = new Date(value);
      }

      function isIsoDate(value): boolean {
        return IsoDateRegex.test(value) ? true : false;
      }
    }

    return modifiedValue;
  }

  getDaysUntilResetNewBudget(value: TimePeriod): number {
    if (!(value)) { return null; }
    let nextDate = this.getNewBudgetResetDate(value);
    let today = new Date();
    var timeDiff = Math.abs(nextDate.getTime() - today.getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  getNewBudgetResetDate(value: TimePeriod): Date {
    let resetDate = {
      monthly: getMonthlyStartDate(),
      quarterly: getQuarterlyStartDate(),
      biyearly: getBiyearlyStartDate(),
      yearly: getYearlyStartDate()
    };

    let nextResetDate: Date = new Date();

    switch (value) {
      case TimePeriod.Monthly:
        nextResetDate = resetDate.monthly;
        break;
      case TimePeriod.Quarterly:
        nextResetDate = resetDate.quarterly;
        break;
      case TimePeriod.Biyearly:
        nextResetDate = resetDate.biyearly;
        break;
      case TimePeriod.Yearly:
        nextResetDate = resetDate.yearly;
        break;
      default:
        nextResetDate = null;
    }

    return nextResetDate;

    function getMonthlyStartDate(): Date {
      let date = new Date();
      return new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 1);
    }

    function getQuarterlyStartDate(): Date {
      let date = new Date();
      let startDate = new Date();
      let currentMonth = date.getMonth();
      switch (currentMonth) {
        case 0: case 1: case 2:
          startDate = new Date(date.getFullYear(), 0, 1);
          break;
        case 3: case 4: case 5:
          startDate = new Date(date.getFullYear(), 3, 1);
          break;
        case 6: case 7: case 8:
          startDate = new Date(date.getFullYear(), 6, 1);
          break;
        case 9: case 10: case 11:
          startDate = new Date(date.getFullYear(), 9, 1);
          break;
        default: break;
      }
      return new Date(startDate.getFullYear(), startDate.getMonth() + 3, 1);
    }

    function getBiyearlyStartDate(): Date {
      let date = new Date();
      let startDate = new Date();
      let currentMonth = date.getUTCMonth();
      switch (currentMonth) {
        case 0: case 1: case 2: case 3: case 4: case 5:
          startDate = new Date(date.getFullYear(), 0, 1);
          break;
        case 6: case 7: case 8: case 9: case 10: case 11:
          startDate = new Date(date.getFullYear(), 6, 1);
          break;
        default: break;
      }
      return new Date(startDate.getFullYear(), startDate.getMonth() + 6, 1);
    }

    function getYearlyStartDate(): Date {
      let date = new Date();
      return new Date(date.getFullYear() + 1, 0, 1);
    }
  }

  getLastBalanceResetDate(value: Date | string) {
    let modifiedValue = null
    if (value) {
      if (isIsoDate(value)) {
        let conversionDate = new Date(value.toLocaleString());
        modifiedValue = new Date(conversionDate.getTime() + conversionDate.getTimezoneOffset() * 60000);
      }
      else {
        modifiedValue = new Date(value);
      }

      function isIsoDate(value): boolean {
        return IsoDateRegex.test(value) ? true : false;
      }
    }

    return modifiedValue;
  }
  previewRewardSite(urlIdentifier, header: string, message: string, productIdentifier: string = null) {

    header = header ? header : "";
    message = message ? message : "";
    let url = `${environment.rewardPortalUrl}market-place/${urlIdentifier}/false?header=${header}&message=${message}`;
    if (productIdentifier) {
      url += `&productIdentifier=${productIdentifier}`;
    }
    window.open(url, "_blank") // using directly to remove circular dependency.
  }

  generateStringFromDates(fromDate: Date, toDate: Date) {
    return fromDate.toDateString() + toDate.toDateString();
  }

  calculateRemainingTime(date: string): number {
    let remainingTime = RedeemTimer.defaultTime;
    if (date) {
      let currentDate = new Date();
      let remainDate = new Date(date);
      remainingTime = Math.abs((remainDate.getTime() - currentDate.getTime())) / 1000;
    }

    return remainingTime;
  }

  roundOff(num: number, places: number): number {
    return Number(+(Math.round(num + "e+" + places as unknown as number) + "e-" + places));
  }

  private mapCategoryFromProduct(product: Product): MarketplaceCategory {
    let { categoryBehaviour, categoryDisplayName, categoryIdentifier, categoryName } = product;
    return { behaviour: categoryBehaviour, displayName: categoryDisplayName, identifier: categoryIdentifier };
  }

  mapPopularProducts(popularProducts: PopularProducts, categories: MarketplaceCategory[], includeTravel: boolean = true): BrowseProduct[] {
    let browseProductList: BrowseProduct[] = [];
    if (popularProducts.experience?.length > 0) {
      let category = this.mapCategoryFromProduct(popularProducts.experience[0]);
      browseProductList.push({
        label: 'Popular Experiences',
        category: category,
        products: popularProducts.experience,
        exploreMore: true,
      })
    }
    if (popularProducts.physicalProduct?.length > 0) {
      let category = this.mapCategoryFromProduct(popularProducts.physicalProduct[0]);
      browseProductList.push({
        label: 'Physical Products',
        category: category,
        products: popularProducts.physicalProduct,
        exploreMore: true,
      })
    }
    if (popularProducts.onlineExperience?.length > 0) {
      let category = this.mapCategoryFromProduct(popularProducts.onlineExperience[0]);
      browseProductList.push({
        label: 'Online Experiences',
        category: category,
        products: popularProducts.onlineExperience,
        exploreMore: true,
      })
    }
    if (popularProducts.foodAndBev?.length > 0) {
      let category = this.mapCategoryFromProduct(popularProducts.foodAndBev[0]);
      browseProductList.push({
        label: 'Food & Beverage',
        category: category,
        products: popularProducts.foodAndBev,
        exploreMore: true,
      })
    }
    if (popularProducts.liveEvent?.length > 0) {
      let category = this.mapCategoryFromProduct(popularProducts.liveEvent[0]);
      browseProductList.push({
        label: 'Upcoming Live Events',
        category: category,
        products: popularProducts.liveEvent,
        exploreMore: true,
      })
    }
    if (popularProducts.physical?.length > 0) {
      let category = this.mapCategoryFromProduct(popularProducts.physical[0]);
      browseProductList.push({
        label: 'Popular Swag',
        category: category,
        products: popularProducts.physical,
        exploreMore: true,
      })
    }
    if (popularProducts.hotel?.length > 0) {
      let category = this.mapCategoryFromProduct(popularProducts.hotel[0]);
      browseProductList.push({
        label: 'Hotels',
        category: category,
        products: popularProducts.hotel,
        exploreMore: false,
      })
    }
    if (includeTravel && popularProducts.travel?.length > 0) {
      let category = this.mapCategoryFromProduct(popularProducts.travel[0]);
      browseProductList.push({
        label: 'Travel',
        category: category,
        products: [{ productGuid: null, image: image_travel_pdp, price: 0, currencyCode: 'USD' } as Product],
        exploreMore: false,
      })
    }

    return browseProductList;
  }

  mapProductCategories(categoriesData: CategoryApiData[]): MarketplaceCategory[] {
    let mappedCategories: MarketplaceCategory[] = [];

    categoriesData?.forEach(x => {
      let category: MarketplaceCategory =
      {
        id: x.category.id,
        identifier: x.category.identifier,
        displayName: x.category.displayName,
        name: x.category.name,
        description: x.category.description,
        behaviour: x.category.behaviour as any,
        imageFileName: x.category.imageFileName,
        iconImages: x.iconImages,
        menuName: x.category.name,
        headerName: x.category.name,
        validForKeywordSearch: x.category.validForKeywordSearch,
        subCategories: x.subCategories,
        isMarketplaceRenderingEnabled: x.category.isMarketplaceRenderingEnabled
      };

      switch (category.name) {
        case CategoryName.Experience:
          category.menuName = category.headerName = 'Experiences';
          break;
        case CategoryName.LiveEvent:
          category.menuName = category.headerName = 'Live Events';
          break;
        case CategoryName.GiftCard:
          category.menuName = category.headerName = 'Gift Cards';
          break;
        case CategoryName.Physical:
        case CategoryName.Swag:
          category.menuName = 'Swag';
          category.headerName = 'Company Swag'
          break;
        case CategoryName.Hotel:
          category.menuName = category.headerName = 'Hotels';
          break;
        case CategoryName.Travel:
          category.menuName = category.headerName = 'Travel';
          break;
        case CategoryName.PhysicalProduct:
          category.menuName = category.headerName = category.displayName;
          break;
        default:
          category.menuName = category.headerName = category.displayName;
      }

      mappedCategories.push(category);
    });

    return mappedCategories;
  }

  mapRewardCategoriesToDisplay(categories: any[]) {
    let rewardCategories = categories.map(x => {
      x.displayName = x.displayName ? x.displayName : x.name;
      x.name = `Allow recipient to select from <span class="bold">${x.displayName ? x.displayName : x.name}</span>`;
      return x;
    });

    if (rewardCategories.length > 0) {
      rewardCategories.push(
        { id: "", guid: null, name: `Create a <span class="bold">Custom Collection</span> of Rewards`, displayName: "Custom Collection" }
      );
    }

    rewardCategories = [{ id: "", guid: null, name: `Allow recipient to select from all products in the <span class="bold">Marketplace</span>`, displayName: "All products in the Marketplace" }, ...rewardCategories];

    return rewardCategories;
  }

  compareTwoObjectsEqual(object1: object, object2: object) {
    const ok = Object.keys;
    const tx = typeof object1;
    const ty = typeof object2;

    return object1 && object2 && tx === 'object' && tx === ty ? (
      ok(object1).length === ok(object2).length &&
      ok(object1).every(key => this.compareTwoObjectsEqual(object1[key], object2[key]))
    ) : (object1 === object2);
  }

  removeTimeZonePart(dateString): string {
    let finalDate = '';
    if (dateString.split('+').length > 1) {
      let splitDate = dateString.split('+');
      finalDate = splitDate[0];
    }
    else {
      let splitDate = dateString.split('-');
      if (splitDate.length > 1) {
        splitDate.pop();
        finalDate = splitDate.join('-');
      }
    }
    return finalDate;
  }

  convertTo12HourFormat(time) {
    if (!time) return "";
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM ' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  sortTime(asc: boolean, times = []) {
    return times.sort(function (a, b) {
      let parsedA = new Date('1970/01/01 ' + a.formatedTime) as any;
      let parsedB = new Date('1970/01/01 ' + b.formatedTime) as any;
      return asc ? parsedA - parsedB : parsedB - parsedA;
    });
  }

  injectServicateScript() {
    // insert servicate script to Body
    if (environment.production) {  
      let script = document.createElement('script');
      script.src = "../../../../../assets/javascript/servicate/servicate.js";
      script.type = 'text/javascript';
      script.id = "servicateScript";
      document.getElementsByTagName('body')[0].appendChild(script);
    }
  }

  removeServicateScript() {
    // remove servicate script from HEAD
    let script = document.getElementById('servicateScript');
    let scriptCode = document.getElementById('servicateScriptCode');
    if (script) {
      document.getElementsByTagName('body')[0].removeChild(script);
    }
    if (scriptCode) {
      document.getElementsByTagName('head')[0].removeChild(scriptCode);
    }
  }

  getFormattedLocationDetails(detail) {
    if (!detail) return null;
    let lat = detail.geometry.location.lat();
    let long = detail.geometry.location.lng();
    let code = detail.address_components?.find((ele) => { if (ele.types?.includes("country")) { return ele; } })?.short_name ?? "";
    let state = detail.address_components?.find((ele) => { if (ele.types?.includes("administrative_area_level_1")) { return ele; } })?.short_name ?? "";
    let image = detail.photos?.length > 0 ? detail.photos[0].getUrl() : "";
    let shortName = detail.name;
    let countryName = detail.address_components?.find((ele) => { if (ele.types?.includes("country")) { return ele; } })?.long_name ?? "";
    return { code, state, lat, long, addr: detail['formatted_address'], placeId: detail.place_id, image, shortName, countryName };
  }

  uniqueBy(a: Array<any>, key: any): Array<any> {
    var seen = {};
    return a.filter((item) => {
      var k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  private getCategoryById(categories: MarketplaceCategory[], categoryIdentifier: string) {
    return categories.find(x => x.identifier?.toLowerCase() == categoryIdentifier?.toLowerCase())
  }

  getViewRewardParams(productInfo: Product, marketplaceFilter: MarketplaceFilter) {
    productInfo = JSON.parse(JSON.stringify(productInfo));
    delete productInfo.shortDescriptionHtml;
    delete productInfo.fullDescriptionHtml;
    let product = this._encryptDecryptService.encrypt(productInfo);
    try {
      let location = this._encryptDecryptService.encrypt(marketplaceFilter.location);
      return { product, location };
    } catch (e) {
      console.error(e);
    }
  }

  public getMinimalCategoryData(category: MarketplaceCategory) {
    if (!category) throw ("Category Identifier or display CategoryIdentifier missing");
    const { id, menuName, identifier, behaviour, name, headerName } = category;
    return { id, menuName, identifier, behaviour, name, headerName };
  }

  resetSearchMPResearch() {
    this.resetSearchMPResearchSubject.next(true);
  }

  getResetSearchMPReasrch(): Observable<boolean> {
    return this.resetSearchMPResearchSubject.asObservable();
  }

  mapIdentifierToId(items: any[]) {
    return items.map(x => {
      x.id = x.identifier;
      return x;
    });
  }
  getNewObject = (obj) => JSON.parse(JSON.stringify(obj));

  getRewardTypeOption(isRewardSpecificityAllowed: boolean, isSpotProgramSelectionEnabled: boolean, isWalletRedemptionAllowed: boolean): RewardOptions {
    if (!isWalletRedemptionAllowed) return RewardOptions.SiteOnly;
    else if (!isRewardSpecificityAllowed && !isSpotProgramSelectionEnabled) return RewardOptions.AmountOnly;
    else if (isRewardSpecificityAllowed && !isSpotProgramSelectionEnabled) return RewardOptions.CategoryOnly;
    else if (!isRewardSpecificityAllowed && isSpotProgramSelectionEnabled) return RewardOptions.SiteWithCategory;
    else if (isRewardSpecificityAllowed && isSpotProgramSelectionEnabled) return RewardOptions.SiteWithCategory;
  }

  htmlToText(html) {
    let temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent;
  }

  getProductAttributeIds = (specificProductItems: any[]) => {
    return specificProductItems?.map(x => {
      if (x.productAttributeGuid)
        return x.productAttributeGuid;
      else x.product.productGuid
    })?.filter(x => x);
  }

  openSnackbarMessage(message: string, type: WlSnackbarType = WlSnackbarType.Alert, infoType: WlSnackbarInfoType = WlSnackbarInfoType.Info, dismissWithPrimaryAction: string = "Okay") {
    if (!message)
      return;

    let snackbarConfig = new WlSnackbarConfig();
    snackbarConfig.data.message = message;
    snackbarConfig.data.type = type;
    snackbarConfig.data.infoType = infoType;
    snackbarConfig.data.dismissWithPrimaryAction = dismissWithPrimaryAction;
    this._wlSnackbarService.openSnackBar(snackbarConfig);
  }

  getDisplayRoleName(role) {
    return USER_ROLE_DISPLAY_MAPPER[role];
  }

  getMPFilterParams(mpFilter: MarketplaceFilter, pagination: ProductCatalogPagination = null, subCategoryIdentifiers: string[] | null = [], categoryIdentifiers: string[] | null = [], startDate: string | Date | null = null, endDate: string | Date | null = null, startTime: string | null = null, availabilityId: number | null = null, gCFilter: GCFilter | null = null, bannerIdentifier: string = null, keywordSearch: string = null): MarketPlaceFilterRequest {
    let filters: MarketPlaceFilterRequest = {
      prodFilter: {
        location: {
          name: mpFilter?.location?.shortName ?? "",
          latitude: mpFilter?.location?.lat ?? 0,
          longitude: mpFilter?.location?.long ?? 0,
          countryCode: mpFilter?.location?.code ?? "",
          stateCode: mpFilter?.location?.state ?? null,
          state: mpFilter?.location?.state ?? null,
          placeId: mpFilter?.location?.placeId ?? null,
          countryName: mpFilter?.location?.countryName ?? null,
          searchState: mpFilter?.location?.placeType ?? null
        },

        subCategoryIdentifiers: subCategoryIdentifiers ?? [],
        keywordSearch: keywordSearch,
        categoryIdentifiers: categoryIdentifiers ?? []
      },
      pagination: pagination,
      gCFilter: {
        identifier: gCFilter?.identifier ?? null,
        userSelectedPrice: gCFilter?.userSelectedPrice ?? null
      },
      externalFilter: {
        startDate,
        endDate,
        lEFilter: {
          latitude: environment.production ? mpFilter?.location?.lat ?? 0 : 0,
          longitude: environment.production ? mpFilter?.location?.long ?? 0 : 0,
          cityState: mpFilter.location.addr,
        },
        fHFilter: {
          availabilityId: null
        },
        viatorFilter: {
          startTime: null
        },
      }
    }

    if (bannerIdentifier) {
      filters.prodFilter.bannerFilter = {
        identifier: bannerIdentifier ?? "",
        prodTopicVector: "",
        prodVectorMetadata: ""
      }
    }

    return filters;
  }

  getBannerDisplayPageNameByCategory(category: MarketplaceCategory): string | null {
    switch (category?.name) {
      case CategoryName.Experience:
        return PAGE_EXPERIENCE_COLLECTION;
      case CategoryName.Swag:
        return PAGE_SWAP_COLLECTION;
      case CategoryName.PhysicalProduct:
        return PAGE_PHYSICAL_COLLECTION;
      case CategoryName.OnlineExperience:
        return PAGE_ONLINE_COLLECTION;
      case CategoryName.FoodAndBev:
        return PAGE_MP_FOOD_AND_BEV;
      default:
        return null;
    }
  }

  navigateToRelatedCategory(displayCatIdentifier: string, categories: MarketplaceCategory[]) {
    if (displayCatIdentifier) {
      const category = categories?.find(x => x.identifier == displayCatIdentifier) ?? null;
      if (category) {
        delete category.subCategories;
        if (category.behaviour != CategoryBehavior.Travel)
          this._router.navigate([MarketplaceRoute.Listing], { queryParams: { category: this._encryptDecryptService.encrypt(category) } });
        else
          this._router.navigate([MarketplaceRoute.Travel], { queryParams: { category: this._encryptDecryptService.encrypt(category) } });
      }
    } else {
      this._router.navigate([MarketplaceRoute.Home])
    }
  }

  getUrlParamFromString(url: string) {
    const urlSearchParams = new URLSearchParams(url);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params;
  }

  getMappedAutomatedOccasion() {
    return {
      Milestone: "Anniversary",
      Birthday: "Birthday",
      Holiday: "Holiday",
      Hire: "Hire"
    }
  }
  prepareInitials(userName: string) {
    let initials = "";
    const nameInitials = userName?.split(' ')
    if (nameInitials.length >= 2)
      initials = nameInitials[0].charAt(0) + '' + nameInitials[1].charAt(0)
    else
      initials = userName ? userName.charAt(0) + '' + userName.charAt(1) : '';

    return initials;
  }

  getExactRelativeUrlWithoutParams = (url: string) => url ? url.split('?')[0] : url;

  getRewardAmtValidationMessage(amount: number, maxAmount: number, choiceSuggestion: ChoiceSuggestion): string {
    if (maxAmount && amount > choiceSuggestion?.amount)
      return `Reward amount cannot be greater than ${choiceSuggestion?.currencyCode} ${choiceSuggestion?.maxAmount}`;
    else if (amount < 0)
      return 'Reward amount can not be a negative value';
    else
      return 'Reward amount is required';
  }

  setZipCode(zipCode: string) {
    this.zipCodeHandler$.next(zipCode?.trim());
  }

  getZipCode = (): Observable<string> => this.zipCodeHandler$.asObservable();

  isUserNameEmail(val: string, isSmsLoginEnabled: boolean = true) {
    if (isSmsLoginEnabled && val?.trim()?.length >= 2)
      return isNaN(+val?.trim()?.replace(/[ +()-]/g, ''));
    return true;
  }

  getPerRowProductCount = (containerWidth: number, itemWidth, totalProducts: number): { productPerRowCount: number, emptyItems: number } => {
    let productPerRowCount = 4;
    let emptyItems = 0;
    if (containerWidth && itemWidth) {
      const itemsPerRow = Math.floor(containerWidth / itemWidth);
      productPerRowCount = itemsPerRow ?? 4;
      const remainingSpaceCount = totalProducts % itemsPerRow;

      if (remainingSpaceCount > 0)
        emptyItems = productPerRowCount - remainingSpaceCount;
    }

    return { productPerRowCount, emptyItems }
  }

  awaiter = (milliseconds: number) => new Promise(resolve => setTimeout(resolve, milliseconds));

  groupItems<T>(items: T[], parentKey: string, childKey: string): { parent: T; children: T[] }[] {
    const childrenMap = new Map<string, T[]>();
    const parentItems: T[] = [];

    // Classify items as parents or children
    items.forEach(item => {
      if (item[childKey]) {
        const children = childrenMap.get(item[childKey]) || [];
        children.push(item);
        childrenMap.set(item[childKey], children);
      } else {
        parentItems.push(item);
      }
    });

    // Construct grouped data
    return parentItems.map(parent => ({
      parent,
      children: childrenMap.get(parent[parentKey]) || [],
    }));
  }

  getInRangeRandomInteger = (min: number, max: number) =>  Math.floor(Math.random() * (max - min + 1) + min);
  
  resolveLoginPageBgImageUrl = (): string => new Date() < occasion_last_date ? occasional_login_background : login_bg;
  
  getAllCountries = (searchTerm: string = "") => {
    return COUNTRY_LIST.filter(x => x.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase())).map(x => {
      const countryNameSplit = x.split('(');
      return {
        countryCode: countryNameSplit[1].replace(')', '')?.trim() ?? "",
        countryName: countryNameSplit[0].replace(')', '')?.trim() ?? "",
      }
    });
  };

  getDateWithDateTimeUTC(dateToModify, timeToModify, timezoneOffset = 330) {
    console.log(dateToModify, timeToModify);
    const date = new Date(dateToModify);
    const time = { hours: timeToModify.split(":")[0], minute: timeToModify.split(":")[1] }

    const utcDate = new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.hours,
      time.minute,
      0
    ));

    utcDate.setUTCMinutes(utcDate.getUTCMinutes() + timezoneOffset);

    return utcDate;
  }
}


const USER_ROLE_DISPLAY_MAPPER = {
  EmployeeLogin: 'Employee',
  Accountant: 'Accountant',
  RewardSender: 'Reward Sender',
  ProgramManager: 'Program Admin',
  Default: 'Default'
}