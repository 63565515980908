import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { RecentActivity } from '../../modals/recent.activity';

export enum ActivityActionTypes {
  RECENT_ACTIVITY_Loading = "[RECENT_ACTIVITY] loading recent activity",
  RECENT_ACTIVITY_REQUESTED = "[RECENT_ACTIVITY] recent activity requested",
  RECENT_ACTIVITY_LOADED = "[RECENT_ACTIVITY_LOADED] recent activity loaded",
  RECENT_ACTIVITY_READ_UNREAD = "[RECENT_ACTIVITY_MARK] get marked activity read unread",
  RECENT_ACTIVITY_CANCELED = "[RECENT_ACTIVITY_CANCELED] recent activity error occurred",
  RECENT_ACTIVITY_UPDATE = "[RECENT_ACTIVITY_UPDATE] update recent activity Read/Unread",
  RECENT_ACTIVITY_ERROR = "[RECENT_ACTIVITY] loading error",
  RECENT_ACTIVITY_REFRESH = "[RECENT_ACTIVITY] refresh requested",
  RECENT_ACTIVITY_REFRESH_COMPLETED = "[RECENT_ACTIVITY] refresh completed",
  RECENT_ACTIVITY_SOFT_REFRESH = "[RECENT_ACTIVITY] FE Only reset",
  RECENT_ACTIVITY_SEEN_LOADED = "[RECENT_ACTIVITY_LOADED] recent activity loaded",
  RECENT_ACTIVITY_UNSEEN_NOTIFICATION = "[RECENT_ACTIVITY_UPDATE] recent activity unseen notification count update ",
}

export interface PageQuery {
  pageIndex: number;
  pageSize: number;
}

export class RecentActivityRequested implements Action {
  readonly type = ActivityActionTypes.RECENT_ACTIVITY_REQUESTED;
  constructor(public payload: { query: PageQuery }, public existingPayload: { unseenCount: number, hasPreviousNotification: boolean }) {
  }
}

export class RecentActivityLoaded implements Action {
  readonly type = ActivityActionTypes.RECENT_ACTIVITY_LOADED;
  constructor(public payload: { recentActivities: RecentActivity[], hasNotifications: boolean, mentionsCount: number, remindersCount: number, recommendationsCount: number, allCount: number }) {
  }
}

export class RecentActivityLoadingError implements Action {
  readonly type = ActivityActionTypes.RECENT_ACTIVITY_ERROR;
  constructor(public payload: { errorMessage: string }) {
  }
}

export class RecentActivityMarkReadUnread implements Action {
  readonly type = ActivityActionTypes.RECENT_ACTIVITY_READ_UNREAD;
  constructor(public payload: { unseenCount: number, hasPreviousNotification: boolean, hasNotifications: boolean, mentionsCount: number, remindersCount: number, recommendationsCount: number, allCount: number }) { }
}

export class RecentActivityUpdateReadUnread implements Action {
  readonly type = ActivityActionTypes.RECENT_ACTIVITY_UPDATE;
  constructor(public payload: { activity: Update<RecentActivity> }) {
  }
}

export class RecentActivityCanceled implements Action {
  readonly type = ActivityActionTypes.RECENT_ACTIVITY_CANCELED
  constructor() {
  }
}

export class RecentActivityRefresh implements Action {
  readonly type = ActivityActionTypes.RECENT_ACTIVITY_REFRESH
  constructor() {
  }
}

export class RecentActivityRefreshCompleted implements Action {
  readonly type = ActivityActionTypes.RECENT_ACTIVITY_REFRESH_COMPLETED
  constructor(public payload: { recentActivities: RecentActivity[], totalCount: number, hasNextPage: boolean }) {
  }
}

export class ResetActivityResetFEOnly implements Action {
  readonly type = ActivityActionTypes.RECENT_ACTIVITY_SOFT_REFRESH
  constructor(public payload: { recentActivities: RecentActivity[] }) {
  }
}

export class RecentActivityUnseenNotificationUpdate implements Action {
  readonly type = ActivityActionTypes.RECENT_ACTIVITY_UNSEEN_NOTIFICATION
  constructor(public payload: { recentActivities: RecentActivity[], unseenCount: number }) {
  }
}
