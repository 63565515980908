import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RecognitionAppModule } from './app/recognition.app.module';
import { environment } from './environments/environment';
import 'hammerjs';
import * as Sentry from "@sentry/angular";

if (environment.production) {
  /**Google Tag Manager Script**/
  let gtm_script = document.createElement('script');
  gtm_script.async = true;
  gtm_script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
   })(window,document,'script','dataLayer','${environment.googleTagManagerId}');`
  document.head.appendChild(gtm_script);
  enableProdMode();
}

/* Inject Google MapLibrary with places*/
(function () {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapApiKey}&loading=async&libraries=places`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
})();

Sentry.init({
  dsn: environment.sentryDns,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/betarecognition.enjoywishlist.com\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
(function(){
    // Disable console.log, console.warn, and console.error
    if(environment.env != 'local'){
      console.log = function () {};
      console.warn = function () {};
      console.error = function () {};
    }
})();

platformBrowserDynamic().bootstrapModule(RecognitionAppModule).then(() => {
}).catch(err => console.error(err));
