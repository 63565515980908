export const COUNTRY_LIST = [
  "Afghanistan (AF)",
  "Albania (AL)",
  "Algeria (DZ)",
  "American Samoa (AS)",
  "Andorra (AD)",
  "Angola (AO)",
  "Anguilla (AI)",
  "Antarctica (AQ)",
  "Antigua and Barbuda (AG)",
  "Argentina (AR)",
  "Armenia (AM)",
  "Aruba (AW)",
  "Australia (AU)",
  "Austria (AT)",
  "Azerbaijan (AZ)",
  "Bahamas (BS)",
  "Bahrain (BH)",
  "Bangladesh (BD)",
  "Barbados (BB)",
  "Belarus (BY)",
  "Belgium (BE)",
  "Belize (BZ)",
  "Benin (BJ)",
  "Bermuda (BM)",
  "Bhutan (BT)",
  "Bolivia (BO)",
  "Bosnia and Herzegovina (BA)",
  "Botswana (BW)",
  "Bouvet Island (BV)",
  "Brazil (BR)",
  "British Indian Ocean Territory (IO)",
  "British Virgin Islands (VG)",
  "Brunei (BN)",
  "Bulgaria (BG)",
  "Burkina Faso (BF)",
  "Burundi (BI)",
  "Cambodia (KH)",
  "Cameroon (CM)",
  "Canada (CA)",
  "Cape Verde (CV)",
  "Cayman Islands (KY)",
  "Central African Republic (CF)",
  "Chad (TD)",
  "Chile (CL)",
  "China (CN)",
  "Christmas Island (CX)",
  "Cocos[Keeling] Islands (CC)",
  "Colombia (CO)",
  "Comoros (KM)",
  "Congo[DRC] (CD)",
  "Congo[Republic] (CG)",
  "Cook Islands (CK)",
  "Costa Rica (CR)",
  "Croatia (HR)",
  "Cuba (CU)",
  "Cyprus (CY)",
  "Czech Republic (CZ)",
  "Denmark (DK)",
  "Djibouti (DJ)",
  "Dominica (DM)",
  "Dominican Republic (DO)",
  "Ecuador (EC)",
  "Egypt (EG)",
  "El Salvador (SV)",
  "Equatorial Guinea (GQ)",
  "Eritrea (ER)",
  "Estonia (EE)",
  "Eswatini (SZ)",
  "Ethiopia (ET)",
  "Falkland Islands[Islas Malvinas] (FK)",
  "Faroe Islands (FO)",
  "Fiji (FJ)",
  "Finland (FI)",
  "France (FR)",
  "French Guiana (GF)",
  "French Polynesia (PF)",
  "French Southern Territories (TF)",
  "Gabon (GA)",
  "Gambia (GM)",
  "Georgia (GE)",
  "Germany (DE)",
  "Ghana (GH)",
  "Gibraltar (GI)",
  "Greece (GR)",
  "Greenland (GL)",
  "Grenada (GD)",
  "Guadeloupe (GP)",
  "Guam (GU)",
  "Guatemala (GT)",
  "Guernsey (GG)",
  "Guinea (GN)",
  "Guinea - Bissau (GW)",
  "Guyana (GY)",
  "Haiti (HT)",
  "Heard Island and McDonald Islands (HM)",
  "Honduras (HN)",
  "Hong Kong (HK)",
  "Hungary (HU)",
  "Iceland (IS)",
  "India (IN)",
  "Indonesia (ID)",
  "Iran (IR)",
  "Iraq (IQ)",
  "Ireland (IE)",
  "Isle of Man (IM)",
  "Israel (IL)",
  "Italy (IT)",
  "Jamaica (JM)",
  "Japan (JP)",
  "Jersey (JE)",
  "Jordan (JO)",
  "Kazakhstan (KZ)",
  "Kenya (KE)",
  "Kiribati (KI)",
  "Kosovo (XK)",
  "Kuwait (KW)",
  "Kyrgyzstan (KG)",
  "Laos (LA)",
  "Latvia (LV)",
  "Lebanon (LB)",
  "Lesotho (LS)",
  "Liberia (LR)",
  "Libya (LY)",
  "Liechtenstein (LI)",
  "Lithuania (LT)",
  "Luxembourg (LU)",
  "Macau (MO)",
  "Madagascar (MG)",
  "Malawi (MW)",
  "Malaysia (MY)",
  "Maldives (MV)",
  "Mali (ML)",
  "Malta (MT)",
  "Marshall Islands (MH)",
  "Martinique (MQ)",
  "Mauritania (MR)",
  "Mauritius (MU)",
  "Mayotte (YT)",
  "Mexico (MX)",
  "Micronesia (FM)",
  "Moldova (MD)",
  "Monaco (MC)",
  "Mongolia (MN)",
  "Montenegro (ME)",
  "Montserrat (MS)",
  "Morocco (MA)",
  "Mozambique (MZ)",
  "Myanmar[Burma] (MM)",
  "Namibia (NA)",
  "Nauru (NR)",
  "Nepal (NP)",
  "Netherlands (NL)",
  "Netherlands Antilles (AN)",
  "New Caledonia (NC)",
  "New Zealand (NZ)",
  "Nicaragua (NI)",
  "Niger (NE)",
  "Nigeria (NG)",
  "Niue (NU)",
  "Norfolk Island (NF)",
  "North Korea (KP)",
  "Northern Mariana Islands (MP)",
  "Norway (NO)",
  "Oman (OM)",
  "Pakistan (PK)",
  "Palau (PW)",
  "Palestinian Territories (PS)",
  "Panama (PA)",
  "Papua New Guinea (PG)",
  "Paraguay (PY)",
  "Peru (PE)",
  "Philippines (PH)",
  "Pitcairn Islands (PN)",
  "Poland (PL)",
  "Portugal (PT)",
  "Puerto Rico (PR)",
  "Qatar (QA)",
  "Romania (RO)",
  "Russia (RU)",
  "Rwanda (RW)",
  "Saint Helena (SH)",
  "Saint Kitts and Nevis (KN)",
  "Saint Lucia (LC)",
  "Saint Pierre and Miquelon (PM)",
  "Saint Vincent and the Grenadines (VC)",
  "Samoa (WS)",
  "San Marino (SM)",
  "Saudi Arabia (SA)",
  "Senegal (SN)",
  "Serbia (RS)",
  "Seychelles (SC)",
  "Sierra Leone (SL)",
  "Singapore (SG)",
  "Slovakia (SK)",
  "Slovenia (SI)",
  "Solomon Islands (SB)",
  "Somalia (SO)",
  "South Africa (ZA)",
  "South Georgia and the South Sandwich Islands (GS)",
  "South Korea (KR)",
  "Spain (ES)",
  "Sri Lanka (LK)",
  "Sudan (SD)",
  "Suriname (SR)",
  "Sweden (SE)",
  "Switzerland (CH)",
  "Syria (SY)",
  "Taiwan (TW)",
  "Tajikistan (TJ)",
  "Tanzania (TZ)",
  "Thailand (TH)",
  "Timor - Leste (TL)",
  "Togo (TG)",
  "Tokelau (TK)",
  "Tonga (TO)",
  "Trinidad and Tobago (TT)",
  "Tunisia (TN)",
  "Turkey (TR)",
  "Turkmenistan (TM)",
  "Turks and Caicos Islands (TC)",
  "Tuvalu (TV)",
  "Uganda (UG)",
  "Ukraine (UA)",
  "United Arab Emirates (AE)",
  "United Kingdom (GB)",
  "United States (US)",
  "Uruguay (UY)",
  "Uzbekistan (UZ)",
  "Vanuatu (VU)",
  "Vatican City (VA)",
  "Venezuela (VE)",
  "Vietnam (VN)",
  "Wallis and Futuna (WF)",
  "Western Sahara (EH)",
  "Yemen (YE)",
  "Zambia (ZM)",
  "Zimbabwe (ZW)"
];
